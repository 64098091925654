exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-temp-tsx": () => import("./../../../src/pages/index-temp.tsx" /* webpackChunkName: "component---src-pages-index-temp-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakti-tsx": () => import("./../../../src/pages/kontakti.tsx" /* webpackChunkName: "component---src-pages-kontakti-tsx" */),
  "component---src-pages-noma-dev-tsx": () => import("./../../../src/pages/noma-dev.tsx" /* webpackChunkName: "component---src-pages-noma-dev-tsx" */),
  "component---src-pages-noma-tsx": () => import("./../../../src/pages/noma.tsx" /* webpackChunkName: "component---src-pages-noma-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

