module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"minify":false,"displayName":true,"fileName":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../socialscrape-instagram/gatsby-browser.js'),
      options: {"plugins":[],"username":"10493490331","social_scrape_token":"b3170e2f-3935-4be5-b75e-1a81744640ac","access_token":"EAA8dR3hzSMYBAJAX5effYxYXZArCbptThSk7baVQwXb4j6Uvxr2aE7fpaHq9r3OOD16SrSNIMCwvwoGN80OoLYZAA6qsYgKqDPWZCxFXl6PzStMIB2snRS3XpNp22LefFRvXSf3vkSLk3v5NS2WlX84J3j9b1xMxx85xnOKDUnyJhRfEiGsMSg5ZAg5lvD4ZD","instagram_id":"17841448304862278","paginate":5,"maxPosts":5,"hashtags":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Yantramanav:100,300,400,500,600,700","PT Serif:100,300,400,500,600,700","Petrona:400,500","Playfair Display:100,300,400,400i,500,600,700"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Reeni Deco","short_name":"Reeni Deco","start_url":"/","background_color":"#ffffff","theme_color":"#E6D0C3","display":"minimal-ui","icon":"content/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e32d7fc138c2d136594651f6e23f1954"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
